import React from 'react'
import {
  PageProps,
  graphql,
  // Link as GatsbyLink
} from 'gatsby'
import {
  Container,
  Heading,
  Stack,
  Text,
  // VStack,
  // UnorderedList,
  // ListItem,
  // Link,
  SimpleGrid,
} from '@chakra-ui/react'
import Layout from '@/layout/base'
import SEO from '@/components/SEO'
import { PageContext } from '@/utils/types'
import BlogPostWithImage from '@/components/BlogPostWithImage'

interface QueryData {
  allSitePage: {
    edges: Array<{
      node: {
        pageContext: PageContext
        path: string
      }
    }>
  }
  allMdx: {
    edges: Array<{
      node: {
        fields: {
          slug: string
        }
        frontmatter: {
          title: string
          tags: string[]
          category: string
          datePublished: string
          dateModified: string
          author: string
          slug: string
          cover: {
            // publicURL: string
            // childImageSharp: {
            //   fluid: {
            //     base64: number
            //     aspectRatio: string
            //     src: string
            //     srcSet: string
            //     sizes: string
            //   }
            // }
            childImageSharp: {
              gatsbyImageData: any
            }
          }
        }
        excerpt: string
        timeToRead: number
      }
    }>
  }
}

const IndexPage = ({ data }: PageProps & { data: QueryData }) => (
  <Layout>
    <SEO
      title="Blog sobre finanzas personales"
      description="Trisquete.com es un blog sobre finanzas personales."
      canonical="https://trisquete.com/"
    />
    <Container maxW="5xl">
      <Stack
        textAlign="center"
        align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
        >
          Página en{' '}
          <Text as="span" color="orange.400">
            construcción
          </Text>
        </Heading>
        <Text color="gray.500" maxW="3xl">
          Estamos trabajando en este sitio web, pronto estará completo.
        </Text>
      </Stack>
      {/* <VStack pb={20} spacing="2" alignItems="flex-start">
        <Heading as="h2">What we write about</Heading>
        <Text as="p" fontSize="lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          condimentum quam arcu, eu tempus tortor molestie at. Vestibulum
          pretium condimentum dignissim. Vestibulum ultrices vitae nisi sed
          imperdiet. Mauris quis erat consequat, commodo massa quis, feugiat
          sapien. Suspendisse placerat vulputate posuere. Curabitur neque
          tortor, mattis nec lacus non, placerat congue elit.
        </Text>
        <UnorderedList listStylePosition="inside" fontSize="lg">
          {data.allSitePage.edges.map((edge) => (
            <ListItem key={edge.node.path}>
              <Link
                as={GatsbyLink}
                to={edge.node.path}
                _hover={{
                  textDecoration: 'none',
                  color: 'orange.500',
                }}
              >
                {edge.node.path}
              </Link>
            </ListItem>
          ))}
        </UnorderedList>
        <hr />
        <UnorderedList listStylePosition="inside" fontSize="lg">
          {data.allMdx.edges.map((edge) => (
            <ListItem key={edge.node.frontmatter.title}>
              <Link
                as={GatsbyLink}
                to={`/${edge.node.frontmatter.slug}/`}
                _hover={{
                  textDecoration: 'none',
                  color: 'orange.500',
                }}
              >
                {edge.node.frontmatter.title}
              </Link>
            </ListItem>
          ))}
        </UnorderedList>
      </VStack> */}
    </Container>
    <Container maxW="5xl" centerContent pb={7}>
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={10}>
        {data.allMdx.edges.map((edge) => (
          <BlogPostWithImage
            key={`/${edge.node.frontmatter.slug}/`}
            title={edge.node.frontmatter.title}
            slug={`/${edge.node.frontmatter.slug}/`}
            author={edge.node.frontmatter.author}
            date="1 de enero de 2021"
            gatsbyImageData={edge.node.frontmatter.cover}
            excerpt={edge.node.excerpt}
            timeToRead={edge.node.timeToRead}
          />
        ))}
      </SimpleGrid>
    </Container>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query GetAllPagesUrl {
    allSitePage {
      edges {
        node {
          pageContext
          path
        }
      }
    }
    allMdx(
      filter: {
        frontmatter: { category: { eq: "airtm" }, exclude: { ne: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            category
            datePublished
            dateModified
            author
            slug
            cover {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  height: 210
                  width: 445
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  # transformOptions: { fit: FILL }
                  # transformOptions: { fit: CONTAIN }
                  transformOptions: { fit: COVER }
                  # aspectRatio: 16/9
                )
              }
            }
          }
          excerpt
          timeToRead
        }
      }
    }
  }
`
