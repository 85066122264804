import React from 'react'
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
// import { StaticImage } from 'gatsby-plugin-image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

type Props = {
  title: string
  author: string
  slug: string
  date: string
  gatsbyImageData: any
  excerpt: string
  timeToRead: number
}

const BlogPostWithImage = ({
  title,
  author,
  slug,
  date,
  gatsbyImageData,
  excerpt,
  timeToRead,
}: Props) => {
  const image = getImage(gatsbyImageData)
  return (
    <Center py={6}>
      <LinkBox as="article">
        <Box
          maxW="445px"
          w="full"
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow="2xl"
          rounded="md"
          p={6}
          overflow="hidden"
        >
          <Box h="210px" bg="gray.100" mt={-6} mx={-6} mb={6} pos="relative">
            {/* <Image
              src={imageSrc}
              alt={`Imagen de portada de "${title}"`}
              fit="fill"
              w="100%"
              h="100%"
              // as={StaticImage}
              // fluid={featuredImgFluid}
            /> */}
            {image && <GatsbyImage image={image} alt="test" />}
            {/* <StaticImage
            src={imageSrc}
            alt={`Imagen de portada de "${title}"`}
            as={Image}
            fit="fill"
            w="100%"
            h="100%"
          /> */}
          </Box>
          <Stack>
            <Text
              color="green.500"
              textTransform="uppercase"
              fontWeight={800}
              fontSize="sm"
              letterSpacing={1.1}
            >
              Blog
            </Text>
            <Heading
              color={useColorModeValue('gray.700', 'white')}
              fontSize="2xl"
              fontFamily="body"
            >
              <LinkOverlay to={slug} as={GatsbyLink}>
                {title}
              </LinkOverlay>
            </Heading>
            <Text color="gray.500">{excerpt}</Text>
          </Stack>
          <Stack mt={6} direction="row" spacing={4} align="center">
            <Avatar
              src="https://avatars0.githubusercontent.com/u/1164541?v=4"
              alt="Author"
            />
            <Stack direction="column" spacing={0} fontSize="sm">
              <Text fontWeight={600}>{author}</Text>
              <Text color="gray.500">
                {date} · Se lee en {timeToRead} min
              </Text>
            </Stack>
          </Stack>
        </Box>
      </LinkBox>
    </Center>
  )
}

export default BlogPostWithImage
